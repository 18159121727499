// import Cookies from 'js-cookie'
{/* @TODO: 暂时先用sessionStorage判断用户登录状态，通过getCurrentCustomerInfo接口设置 */}
import { sessionStorageService } from "@/utils/storageService"
const TokenKey = 'had_login'

export function getToken() {
  return sessionStorageService.getItem(TokenKey)
}

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }
