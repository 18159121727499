import { ajax } from '../addCartAxios'

/**
 * 加购接口
 */
export default {
  // 已登录加购接口
  ajaxaddcart(query: string, params: any) {
    return ajax.post('api', `/viewcart/ajaxaddcart${query}`, params)
  },

  // 未登录加购接口
  ajaxaddlocaltocart(query: string, params: any) {
    return ajax.post('api', `/viewcart/ajaxaddlocaltocart${query}`, params)
  },

  // 不算邮费接口加购接口
  addtocart(query: string, params: any) {
    return ajax.post('api', `/viewcart/addtocart${query}`, params)
  },

  // 不算邮费未登录加购接口
  ajaxAddLocalCart(query: string, params: any) {
    return ajax.post('api', `/viewcart/ajaxAddLocalCart${query}`, params)
  },

  // 不算邮费已登录批量加购接口
  batchAddCart(query: string, params: any) {
    return ajax.post('api', `/viewcart/batchAddToCart${query}`, { products: params, isCombine: true })
  },
}