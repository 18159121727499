import { useState, useEffect } from 'react'
import styles from '@/styles/weeklyDeals.module.scss'
import GoodsComponent from '@/components/GoodsComponent'
import SwiperSelf from '@/components/SwiperSelf'
import { SwiperSlide } from 'swiper/react';
import Image from 'next/image'
import Link from 'next/link'

import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

interface ProductItem {
  type: string,
  productID: string;
  productName: string;
  url: string;
  imgUrl: string;
  promotion: string;
  review: number;
  reviewCount: number;
  price: number;
  promotionPrice: number;
  tagTitle: string;
  tagBgColor: string;
  tagFontColor: string;
  freeShipping: number;
  onSale: number;
  reduced: number;
  isNew: number;
  isEmailOnly: any,
  promotionTypeID: string | number;
}

interface Props {
  weeklySalesData?: {
    mainImgUrl: string,
    ipadImgUrl: string,
    title: string,
    link: string,
    productList: ProductItem[],
  } | null
}

export default function WeeklyDeals({ weeklySalesData }: Props) {
  const [slidesPerView, setSlidesPerView] = useState(0)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  function onSlideChange(isBeginning: boolean, isEnd: boolean) {
    setIsBeginning(isBeginning)
    setIsEnd(isEnd)
  }

  useEffect(() => {
    // 监听页面resize事件, 根据屏幕大小改变轮播图展示个数
    function handleResize() {
      if (window.innerWidth >= 1358) {
        setSlidesPerView(4.5)
      } else  {
        setSlidesPerView(4)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, [])

  function handlePageJump(url: string, e: any) {
    // 阻止a标签默认行为
    e.preventDefault()

    window.location.href = `${url}`
  }

  return (
    <>
      <div className={styles['weekly-deals-box']}>
        <div className={styles['deals']}>
          <Link 
            href={weeklySalesData?.link || ''} 
            legacyBehavior
          >
            <a onClick={(e: any) => handlePageJump(`${apiDomain}${weeklySalesData?.link || ''}`, e)} >
              <Image
                className={styles['main-img']}
                src={weeklySalesData?.mainImgUrl || ''}
                alt={weeklySalesData?.title || ''}
                width={224}
                height={362}></Image>
              <Image
                className={styles['pad-img']}
                src={weeklySalesData?.ipadImgUrl || ''}
                alt={weeklySalesData?.title || ''}
                width={224}
                height={362}></Image>
            </a>
          </Link>
        </div>
        <div className={styles['crazy-deals-goods']}>
          {
            !!slidesPerView && 
            <SwiperSelf 
              slidesPerView={slidesPerView} 
              slideChange={onSlideChange} 
              loop={true} 
              autoplay={true} 
              delay={8000}
            >
              {
                weeklySalesData?.productList.length &&
                weeklySalesData?.productList.map((item: ProductItem, index:number) => (
                  <SwiperSlide key={item.productID}>
                    <div className={styles['product-list']}>
                      <GoodsComponent {...item} position={index} location="homepage_crazyDeal" type='CrazyDeal' />
                    </div>
                  </SwiperSlide>
                ))
              }
            </SwiperSelf>
          }
          {
            !isBeginning
            &&
            <div className={styles['gradient-mask-left']}></div>
          }
          {
            !isEnd
            &&
            <div className={styles['gradient-mask-right']}></div>
          }
        </div>
      </div>
    </>
  )
}
