import styles from '@/styles/reviews.module.scss'
import { Rate } from 'antd'
import Image from "next/image"
import Link from 'next/link'
import { useState } from "react"
import { SwiperSlide } from 'swiper/react'
import SwiperSelf from '@/components/SwiperSelf'
import { LinkOutlined } from '@ant-design/icons'
import getConfig from 'next/config'

import GoogleReviewIcon from '@/svgs/googleReviewIcon.svg'


const { publicRuntimeConfig } = getConfig()
const apiDomain = publicRuntimeConfig.apiDomain

interface BannerItem {
  imgUrl: string;
  title: string;
  link: string;
}

interface ReviewItem {
  name: string;
  star: number,
  review: string;
  createDateTime: string;
}

interface props {
  footBanner: BannerItem,
  reviewData?: ReviewItem[],
  reviewSummary?: any
}

export default function Reviews({ footBanner, reviewData, reviewSummary }: props) {
  /**
   * 页面底部的Subscribe
   */
  const [bottomSubscribeValue, setbottomSubscribeValue] = useState("")
  const handleBottomSubscribeChange = (event: any) => {
    setbottomSubscribeValue(event.target.value)
  }
  const handleSendSubscribe = () => {
    window.location.href = `${apiDomain}/newsletters?email=${bottomSubscribeValue}`
  }
  return (
    <div className={styles['reviews']}>
      <div className={styles['content']}>

      <Link href={`${apiDomain}/review`} legacyBehavior>
          <a>
            <h3 className={styles['title']}>
              <GoogleReviewIcon></GoogleReviewIcon>
              <p>{reviewSummary.avgScore} stars with {reviewSummary.count}+ reviews</p>
              <LinkOutlined style={{ color: 'red', fontSize: '28px', marginLeft: '8px', marginTop: '4px' }} />
            </h3>
          </a>
        </Link>

        {
          reviewData && reviewData.length &&
          <SwiperSelf 
            slidesPerView={3} 
            slidesPerGroup={1} 
            loop={true} 
            autoplay={true} 
            direction={'vertical'} 
            hideArrows={true}
            height={400}
          >
            {
              reviewData.map((item, index) =>
                <SwiperSlide key={index}>
                  <div className={styles['item']}>
                    <div className={styles['info']}>
                      <div className={styles['user-name']}>{item.name}</div>
                      <Rate style={{ color: '#ffc33d', fontSize: '16px', marginBottom: '4px' }} allowHalf disabled value={item.star} />
                      <div className={styles['time']}>{item.createDateTime}</div>
                    </div>
                    <div className={styles['commit']}>
                      <p>
                        {item.review}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              )
            }
          </SwiperSelf>
        }

      </div>
      <div className={styles["bottom-subscribe"]}>
        <Link href={`${apiDomain}/newsletters`} legacyBehavior>
          <a>
            <Image
              alt="Subscribe"
              width={224}
              height={500}
              src={footBanner.imgUrl}
            />
          </a>
        </Link>
 
       <div className={styles["subscribe-form"]}>
         <input
           value={bottomSubscribeValue}
           onChange={handleBottomSubscribeChange}
           placeholder="Email Address"
         ></input>
         <div className={styles["subscribe-btn"]} onClick={handleSendSubscribe}>
           Subscribe
         </div>
       </div>
     </div>
    </div>
  )
}