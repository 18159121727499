import styles from '@/styles/shopInfo.module.scss'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { Modal } from 'antd'
import { Image } from 'antd'

interface Props {
  hiddenContactus?: boolean
}

export default function ShopInfo({ hiddenContactus }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');
  const [width, setWidth] = useState(0)

  let shopInfoList = [{
    name: 'point',
    img: 'https://static.crazysales.com.au/images/2022v/svg/services/icon-service1-color.svg',
    title: 'Earn CrazyPoints with our Reward Program!',
    openType: 'dialog',
    url: 'https://www.crazysales.com.au/article/EarnCrazyPoints?loadType=true'
  }, {
    name: '30day',
    img: 'https://static.crazysales.com.au/images/2022v/svg/services/icon-service2-color.svg',
    title: '30 Day Change- of-Mind Policy',
    openType: 'dialog',
    url: 'https://www.crazysales.com.au/article/30DayChangeMind?loadType=true'
  }, {
    name: 'delivery',
    img: 'https://static.crazysales.com.au/images/2022v/svg/services/icon-service3-color.svg',
    title: 'Fast Delivery for Online Shopping in Australia',
    openType: 'dialog',
    url: 'https://www.crazysales.com.au/article/FastDelivery?loadType=true'
  }, {
    name: 'contactus',
    img: 'https://static.crazysales.com.au/images/2022v/svg/services/icon-service4-color.svg',
    title: 'Mon-Fri 8AM to 5PM AEST Call Us on 03-91115708',
    openType: 'link',
    url: 'https://www.crazysales.com.au/contactus'
  }, {
    name: 'safe',
    img: 'https://static.crazysales.com.au/images/2022v/svg/services/icon-service5-color.svg',
    title: 'Safe Online Shopping With Secure Payment',
    openType: 'dialog',
    url: 'https://www.crazysales.com.au/article/SafeOnlineShopping?loadType=true'
  }, {
    name: 'customers',
    img: 'https://static.crazysales.com.au/images/2022v/svg/services/icon-service6-color.svg',
    title: 'Shop Online With Over 1,000,000 Happy Customers',
    openType: 'dialog',
    url: 'https://www.crazysales.com.au/article/ShopWithHappy?loadType=true'
  }, {
    name: 'winning',
    img: 'https://static.crazysales.com.au/images/2022v/svg/services/icon-service7-color.svg',
    title: 'Award-Winning Online Shopping in Australia',
    openType: 'dialog',
    url: 'https://www.crazysales.com.au/article/AwardWinning?loadType=true'
  }]

  if (hiddenContactus) {
    shopInfoList = shopInfoList.filter(item => item.name !== 'contactus')
  }

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleOpen = (openType: string, url: string, e: any) => {
    // 阻止a标签默认行为
    e.preventDefault()

    // 宽度必须大于1024才使用弹窗，否则会有内容溢出弹窗
    if (openType === 'dialog' && width > 1024) {
      setCurrentUrl(url)
      setIsModalOpen(true)
    } else {
      window.location.href = url
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className={styles['shop-info-list']}>
      <div className={styles['shop-info']}>
        {
          shopInfoList.map((item, index) =>
            <Link
              key={index}
              href={item.url}
              passHref
              legacyBehavior
            >
              <a className={styles['item']} onClick={(e: any) => handleOpen(item.openType, item.url, e)} rel="noopener noreferrer">
                <div>
                  <div className={styles['image']}>
                    <Image
                      alt={item.title}
                      width={64}
                      preview={false}
                      src={item.img} />
                  </div>
                  <p
                    className={`${styles['desc']} ${hiddenContactus ? styles['desc-narrow'] : ''}`}
                  >{item.title}</p>
                </div>
              </a>
            </Link>
          )
        }
      </div>

      {
        isModalOpen &&
        <Modal
          title=""
          width={850}
          centered
          footer={null}
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <iframe src={currentUrl} className={styles['shop-iframe']} scrolling="auto"></iframe>
        </Modal>
      }
    </div>
  )
}
