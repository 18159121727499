import { ajax } from '../index'

/**
 * 首页 相关接口
 */
export default {
  // 获取category数据
  getCategoryTree() {
    return ajax.get('api', `/api/v1/category/tree`, {})
  },

  // 获取WeeklyDeals数据
  getWeeklyDeals(dataObj: any) {
    return ajax.get('api', `/api/v1/product/list/weekly-deals`, dataObj)
  },

  // 获取Mooncake数据
  getMoonCakeData() {
    return ajax.get('api', `/api/v1/mooncake/box`, {})
  },

  // 获取recommended数据
  getHomeRecommended(dataObj: any) {
    return ajax.get('api', `/api/v1/recommended/someone`, dataObj)
  },

  // 获取Reviews数据
  getHomeReviews() {
    return ajax.get('api', `/api/v1/review/best-reviews`, {})
  }
}