import { localStorageService } from "@/utils/storageService"
import { getToken } from '@/utils/auth'
import { gaAddToCart } from "@/utils/gaEvent"
import { message } from "antd";
import addCartApi from '@/service/modules/addCartApi'

interface paramsInterFace {
  productID: string | number,
  qty: string | number,
  location?: string,
  position?: number,
  pages?: number
}

// 单个加购
export const addToCart = async (event: any, parameter: paramsInterFace, callback?: Function) => {
  event.stopPropagation()
  const { productID, qty, location, position, pages } = parameter
  const isLogin = getToken() == '1'

  // 船新版本
  try {
    const addCartFunction = isLogin ? addCartApi.addtocart : addCartApi.ajaxAddLocalCart

    // 未登录所需参数
    let localAddCartParams = localStorageService.getItem('copyCartData') ? JSON.parse(`${localStorageService.getItem('copyCartData')}`) : [];
    localAddCartParams.push({ pid: String(productID), qty: qty, selected: 1, upgradeExpress: 0 });

    // 已登录所需参数
    const formData = new FormData()
    formData.append('pid', String(productID))
    formData.append('qty', String(qty))

    const params = isLogin ? formData : JSON.stringify(localAddCartParams)

    // query占坑统计
    let query = ""
    if (qty && location && (position == 0 || position)) {
      query = `?pid=${productID}&location=${location}&position=${position ? position : 0}&page=${pages ? pages : 1}`
    }

    const response = await addCartFunction(query, params)
    // const response = await addCartApi.addtocart(query, params)
    const cartData: any = response

    // 购物车超过100限制提示，有cartItemCountMsg则说明超过100了
    if (cartData && cartData.cartItemCountMsg) {
      message.warning(cartData.cartItemCountMsg)
      throw new Error(cartData.cartItemCountMsg)
    }

    var updatedCart = cartData.cart;
    var data = JSON.stringify(cartData);
    localStorageService.setItem('cartData', data);
    let copyData: any[] | string = []
    if (updatedCart && updatedCart.content) {
      updatedCart.content.forEach((item: any) => Array.isArray(copyData) && copyData.push({ pid: item.productID, qty: item.Quantity, selected: item.select_status, upgradeExpress: item.upgrade_express }))
    }
    localStorageService.setItem('copyCartData', JSON.stringify(copyData));
    window.dispatchEvent(new Event('storageUpdated'));

    callback && callback(cartData)

    // 加购物车GA埋点
    const goodsItem = updatedCart.content.find((v: any) => v.productID == productID)
    gaAddToCart("AUD", goodsItem.Price, [{ ...goodsItem, Quantity: 1 }])

    if (window && (window as any)._paq) {
      (window as any)._paq.push(['setCustomDimension', 28, JSON.stringify({ productID, qty })]);
      (window as any)._paq.push(['trackPageView']);
    }

    // 加购物车emarsys tracking埋点
    let ScarabQueue: any = (window as any).ScarabQueue || [];
    if (ScarabQueue) {
      if (localStorage.getItem('userEmail')) {
        ScarabQueue.push(['setEmail', localStorage.getItem('userEmail')]);
      }

      let scarabQueueCartData = updatedCart.content.map((item: any) => {
        return {
          item: item.productID,
          price: Number(item.Price),
          quantity: Number(item.Quantity)
        }
      })

      console.log(scarabQueueCartData, ' === scarabQueueCartData');

      ScarabQueue.push(['cart', scarabQueueCartData]);
      ScarabQueue.push(['go']);

    }
  } catch (error) {
    console.log(error);
    callback && callback("error")
  }
}


interface batchParams {
  productList: Array<{}>
  location?: string
  position?: number
  pages?: number
}

// 批量加购
export const batchAddToCart = async (event: any, parameter: batchParams, callback?: Function) => {
  event.stopPropagation()
  const { productList } = parameter
  const isLogin = getToken() == '1'

  // 船新版本
  try {
    const addCartFunction = isLogin ? addCartApi.batchAddCart : addCartApi.ajaxAddLocalCart
    let params: any = {}
    if (isLogin) {
      // 已登录所需参数
      const batchParams: any = []
      productList.forEach((item: any) => {
        batchParams.push({
          productID: item.productID,
          Qty: item.qty || 1
        })
      })
      params = batchParams
    } else {
      // 未登录所需参数
      let localAddCartParams = localStorageService.getItem('copyCartData') ? JSON.parse(`${localStorageService.getItem('copyCartData')}`) : [];
      productList.forEach((item: any) => {

        // 购物车已有的产品需要+1
        if (localAddCartParams.some((item2: any) => {
          return item.productID == item2.productID;
        })) {
          localAddCartParams.push({ pid: String(item.productID), qty: parseInt(item.qty) + 1, selected: 1, upgradeExpress: 0 });
        } else {
          localAddCartParams.push({ pid: String(item.productID), qty: item.qty || 1, selected: 1, upgradeExpress: 0 });
        }
      })
      params = JSON.stringify(localAddCartParams)
    }

    const response = await addCartFunction("", params)
    // const response = await addCartApi.addtocart(query, params)
    const cartData: any = response

    // 购物车超过100限制提示，有cartItemCountMsg则说明超过100了
    if (cartData && cartData.cartItemCountMsg) {
      message.warning(cartData.cartItemCountMsg)
      throw new Error(cartData.cartItemCountMsg)
    }

    var updatedCart = cartData.cart;
    var data = JSON.stringify(cartData);
    localStorageService.setItem('cartData', data);
    let copyData: any[] | string = []
    if (updatedCart && updatedCart.content) {
      updatedCart.content.forEach((item: any) => Array.isArray(copyData) && copyData.push({ pid: item.productID, qty: item.Quantity, selected: item.select_status, upgradeExpress: item.upgrade_express }))
    }
    localStorageService.setItem('copyCartData', JSON.stringify(copyData));
    window.dispatchEvent(new Event('storageUpdated'));

    callback && callback(cartData)

  } catch (error: any) {
    console.log(error);
    callback && callback("error")
  }
}