import styles from '@/styles/middleSubscribe.module.scss'
import { useState } from "react"
import Link from 'next/link'
import Image from "next/image"
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

interface BannerItem {
  imgUrl: string;
  title: string;
  link: string;
}

interface Props {
  midBanner: BannerItem,
  midBannerPad: BannerItem,
}

export default function MiddleSubscribe({ midBanner, midBannerPad }: Props) {
  const [middleSubscribeValue, setMiddleSubscribeValue] = useState("")
  const handleMiddleSubscribeChange = (event: any) => {
    setMiddleSubscribeValue(event.target.value)
  }
  const handleSendSubscribe = () => {
    window.location.href = `https://${window.location.host}/newsletters?email=${middleSubscribeValue}`
  }

  return (
    <>
      <div className={styles["middle-subscribe"]} id="middleSubscribe">
        <Link href={`${apiDomain}/newsletters`} legacyBehavior>
          <a>
            <Image
              alt="Subscribe"
              width={1400}
              height={74}
              src={midBanner.imgUrl}
            />
          </a>
        </Link>

        <div className={styles["subscribe-form"]}>
          <input
            value={middleSubscribeValue}
            onChange={handleMiddleSubscribeChange}
            placeholder="Your Email Address Here"
          ></input>
          <div className={styles["subscribe-btn"]} onClick={handleSendSubscribe}>
            Subscribe
          </div>
        </div>
      </div>
      <div className={styles["middle-subscribe-ipad"]} id="middleSubscribeIpad">
        <Link href={`${apiDomain}/newsletters`} legacyBehavior>
          <a>
            <Image
              alt="Subscribe"
              width={1000}
              height={53}
              src={midBannerPad.imgUrl}
            />
          </a>
        </Link>

        <div className={styles["subscribe-form"]}>
          <input
            value={middleSubscribeValue}
            onChange={handleMiddleSubscribeChange}
            placeholder="Your Email Address Here"
          ></input>
          <div className={styles["subscribe-btn"]} onClick={handleSendSubscribe}>
            Subscribe Now!
          </div>
        </div>
      </div>
    </>
  )
}