import styles from '@/styles/exhibition.module.scss'
import Image from 'next/image'
import getConfig from 'next/config'
import Link from 'next/link'

const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

interface moonCakeProductItem {
  productID: string;
  name: string;
  customImgUrl: string;
  url: string;
  price: number;
  normalPrice: number;
  promotion: {
    promotionRate: string;
  };
}

interface moonCakeItemType {
  title: string,
  backgroundStyle: any,
  titleStyle: any,
  priceStyle: any,
  borderStyle: any,
  link: string,

  productList: moonCakeProductItem[]
}
interface props {
  moonCakeData: moonCakeItemType[]
}

export default function Exhibition({ moonCakeData }: props) {
  // 页面跳转
  function handlePageJump(url: string, title: string, pid?: string, e?: any) {
    if (e) e.stopPropagation()

    // let link = `${apiDomain}${url}`
    // if (pid) link = `${apiDomain}${url}?pid=${pid}`

    // ReactGA.event({
    //   category: 'New Home Page',
    //   action: 'Click',
    //   label: `Exhibition - ${title}`,
    // })

    // window.location.href = link
  }
  const exhibitionItems = moonCakeData.map((item: moonCakeItemType) => {
    // 主产品
    const mianProductData = item.productList[0]
    const price = `${mianProductData.price || mianProductData.normalPrice}`.split('.')

    const mianProduct = (
      <div
        className={styles['main-product']}
        onClick={(e: any) => handlePageJump(`${item.link}`, `${item.title}`, `${mianProductData.productID}`, e)}
      >
        <Link
          href={mianProductData.productID ? `${apiDomain}${item.link}?pid=${mianProductData.productID}` : `${apiDomain}${item.link}`}
          legacyBehavior
        >
          <a rel="noopener noreferrer" style={{ display: 'block' }}>
            <Image
              alt={mianProductData.name}
              width={190}
              height={190}
              src={mianProductData.customImgUrl} />
            <div className={styles['mian-product-name']}>{mianProductData.name}</div>
            <div className={styles['mian-product-price']} style={item.priceStyle}>
              {/* 原价和促销价不一样，才展示原价 */}
              {
                mianProductData.price == mianProductData.normalPrice
                ?
                <>
                  <b>${price[0]}<i>.{price.length === 2 ? price[1] : '00'}</i></b>
                </>
                :
                <>
                  <b>${price[0]}<i>.{price.length === 2 ? price[1] : '00'}</i></b>
                  <span className={styles['mian-product-price-gray']}>${mianProductData.normalPrice}</span>
                </>
              }
            </div>
            {
              !!mianProductData.promotion.promotionRate &&
              <div className={styles['mian-product-discount']}>
                <div className={styles['discount-num']}>
                  {mianProductData.promotion.promotionRate}
                </div>
                <div className={styles['discount-text']}>
                  <div className={styles['discount-unit']}>%</div>
                  <div className={styles['discount-off']}>OFF</div>
                </div>
              </div>
            }
          </a>
        </Link>
      </div>
    )

    function handlePageJumpLocation(url: string, e: any) {
      // 阻止a标签默认行为
      e.preventDefault()
      window.location.href = `${url}`
    }

    return (
      <div
        className={styles['exhibition-item']}
        key={item.title}
        style={{ ...item.backgroundStyle, ...item.borderStyle }}
        onClick={() => {handlePageJump(`${item.link}`, `${item.title}`); window.location.href = `${apiDomain}${item.link}`}}
      >
        <Link
          href={`${apiDomain}${item.link}`}
          legacyBehavior
        >
          <a 
            rel="noopener noreferrer"
            onClick={(e: any) => handlePageJumpLocation(`${apiDomain}${item.link}`, e)} 
          >
            <div
              className={styles['exhibition-title']}
              style={item.titleStyle}
            >
              {`${item.title} >`}
            </div>
          </a>
        </Link>

        {mianProduct}

        <div className={styles['product-list']}>
          {
            item.productList.length &&
            item.productList.slice(1).map((product: moonCakeProductItem) => {
              const customProductPrice = `${product.price || product.normalPrice}`.split('.')

              return (
                <div
                  key={product.url}
                  className={styles['product-item']}
                  onClick={(e: any) => handlePageJump(`${item.link}`, `${item.title}`, `${product.productID}`, e)}
                >
                  <Link
                    href={product.productID ? `${apiDomain}${item.link}?pid=${product.productID}` : `${apiDomain}${item.link}`}
                    legacyBehavior
                  >
                    <a 
                      rel="noopener noreferrer"
                      onClick={(e: any) => handlePageJumpLocation(`${product.productID ? apiDomain + item.link + '?pid=' + product.productID : apiDomain + item.link}`, e)} 
                      style={{ display: 'block' }}
                    >
                      <Image
                        alt={product.name}
                        width={173}
                        height={173}
                        src={product.customImgUrl} />
                      <div className={styles['product-name']}>{product.name}</div>
                      <div className={styles['product-price']} style={item.priceStyle}>
                        <b>${customProductPrice[0]}<i>.{customProductPrice.length === 2 ? customProductPrice[1] : '00'}</i></b>
                      </div>
                    </a>
                  </Link>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  })

  return (
    <div className={styles['exhibition-list']}>
      {exhibitionItems}
    </div>
  )
}
